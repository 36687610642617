<template>
  <div class="attribute-selection">
    <p class="name align-vertically">{{ name }}:</p>
    <button
      :class="{ 'input-button': true, shine: choice == 1 }"
      @click="Choose(1, choice1)"
    >
      {{ choice1 }} {{ unit }}
    </button>
    <button
      :class="{ 'input-button': true, shine: choice == 2 }"
      @click="Choose(2, choice2)"
    >
      {{ choice2 }} {{ unit }}
    </button>
    <div :class="{ 'input-space': true, shine: choice == 3 }">
      <div class="custom-input-container">
        <input
          class="custom-input"
          type="text"
          v-model="value"
          @keypress="isNumber($event)"
          @click="Choose(3)"
          placeholder="____"
        />
        <p class="">{{ unit }}&nbsp;&nbsp;&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "choice1", "choice2", "mobile", "unit", "max"],
  data() {
    return {
      choice: 1,
      value: null,
    };
  },
  mounted: async function () {
    this.Choose(1, this.choice1);
  },
  methods: {
    Choose(value, choice) {
      this.value = choice;
      this.choice = value;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal > this.max) {
        this.value = oldVal;
        return;
      }
      this.$emit("change", newVal);
    },
  },
};
</script>

<style scoped>
.name {
  margin: 0px;
  height: 100%;
  grid-area: name;
}
.attribute-selection {
  color: #fff;
  padding-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 10px;
  grid-template-areas:
    "name name name"
    "choice2 choice3 choice4";
}
/*.attribute-selection-mobile {
    max-width: 400px;
    height: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas: 
                "name name name"
                "choice2 choice3 choice4";
}*/
.attribute-selection-mobile > .custom-input-container,
button,
.attribute-selection > .custom-input-container,
button {
  height: 100%;
  border-radius: 5px;
  border: solid rgba(24, 170, 161, 0.25) 1px;
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0);
}
.input-button {
  min-height: 20px;
  padding: 10px 0;
}
.input-space {
  border-radius: 5px;
  border-width: 1px;
  border: solid rgba(24, 170, 161, 0.25) 1px;
  width: 90%;
}
.custom-input-container > input {
  width: 120px;
  outline: none;
  border: none;
  grid-area: as1;
  background-color: rgba(255, 255, 255, 0);
}
.custom-input-container > p {
  text-align: left;
  margin: 0px;
}
.custom-input-container {
  padding: 0 0 0 0;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  grid-template-areas: "as1 as2";
}
.custom-input {
  height: 100%;
  width: 50%;
  text-align: right;
}
.shine {
  border-width: 1px;
  border-color: #18aaa1;
  color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 600px) {
  /*.attribute-selection {
    max-width: 400px;
    height: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas:
      "name name name"
      "choice2 choice3 choice4";
  }*/
}
@media screen and (max-width: 400px) {
  .attribute-selection {
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 1px 10px;
  }
  .custom-input-container {
    grid-template-columns: none;
  }
  .custom-input-container > input {
    width: 100%;
  }
}
</style>