<template >
  <div class="Staker">
    <div class="Staker-wrap">
      <div class="split-two">
        <button
          :class="{ 'switch-on-button': stake, 'switch-off-button': !stake }"
          @click="Switch(true)"
        >
          Stake
        </button>
        <button
          :class="{ 'switch-on-button': !stake, 'switch-off-button': stake }"
          @click="Switch(false)"
        >
          Unstake
        </button>
      </div>
      <div class="poolAction flex-column">
        <div :class="this.stake ? 'inputSpaces' : 'inputSpaces-unstake'">
          <InputSpace
            id="AvaxSpace"
            name="Avax"
            @update="setValue"
            :max="AvaxBal"
            :data="stake ? false : expected"
            ref="InputSpace1"
          ></InputSpace>
          <div id="downButton">
            <ImageVue :size="'32px'" :src="'dynamic/down.png'"></ImageVue>
          </div>
          <InputSpace
            id="gAvaxSpace"
            name="yyAvax"
            @update="setValue"
            :max="gAvaxBal"
            :data="stake ? expected : false"
            ref="InputSpace2"
          ></InputSpace>
          <!-- <div id="price"> -->
          <!-- <div class="warndata">PricePerShare: {{ price.toFixed(5) }}</div> -->
          <!-- <div class="warndata">Daily APR: 0.02%</div> -->
          <!-- <div v-if="dayGap && userAddress" class="warndata"> -->
          <!-- <div v-if="dayGap >= 0" class="warndata"> -->
          <!-- Gained Days: {{ dayGap }} -->
          <!-- </div> -->
          <!-- <div v-else>Lost Days: {{ dayGap * -1 }}</div> -->
          <!-- </div> -->
          <!-- </div> -->
        </div>
        <div>
          <div class="left-section">
            <AttributeSelection
              :mobile="mobile"
              name="Max Slippage"
              choice1="0.1"
              choice2="0.5"
              :unit="'%'"
              :max="100"
              @change="setSlippage"
            ></AttributeSelection>
            <AttributeSelection
              :mobile="mobile"
              name="Tx Deadline"
              choice1="10"
              choice2="30"
              :unit="'min'"
              @change="setDeadline"
            ></AttributeSelection>
            <div class="text-right gap-53">
              <div class="content-right align-vertically" v-if="!stake">
                <p class="display-inline">ERC1155 Approval</p>
                <CheckWrapper :value="approval" />
              </div>
            </div>
          </div>
        </div>
        <div class="align-vertically content-right flex-row button-wrap">
          <Button
            :loading="loading"
            class="Execute-button"
            :text="ButtonText"
            :disabled="isDisabled"
            @click.native="Execute()"
            :color="isDisabled ? 'transparent' : '18aaa180'"
            :fill="true"
            :whiteText="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttributeSelection from "@/components/utils/AttributeSelection";
import InputSpace from "@/components/utils/InputSpace";
import { mapGetters, mapActions } from "vuex";
import { notifyHandler } from "@/utils/common";
import { getWithdrawalPool } from "@/contracts";
import { timeout } from "@/utils/common";

import BN from "bignumber.js";
import ImageVue from "@/components/utils/ImageVue";
import Button from "@/components/utils/ButtonVue";
import CheckWrapper from "@/components/utils/CheckWrapper";
BN.config({ ROUNDING_MODE: BN.ROUND_DOWN });
BN.config({ EXPONENTIAL_AT: 100 });
export default {
  components: {
    AttributeSelection,
    InputSpace,
    ImageVue,
    Button,
    CheckWrapper,
  },
  props: ["mobile"],
  data() {
    return {
      chosenID: 0,
      stake: true,
      Avax: 0,
      gAvax: 0,
      AvaxBal: 0,
      gAvaxBal: 0,
      expected: 0,
      price: BN(1),
      slippage: 0,
      deadline: 0,
      approval: false,
      wpAddress: "",
      loading: true,
    };
  },
  mounted: async function () {
    this.chosenID = this.$route.params.id;
    await this.updateData();
  },
  computed: {
    ...mapGetters({
      userAddress: "userAddress",
      web3: "web3",
      contracts: "contracts",
      planetIds: "planetIds",
    }),
    ButtonText: function () {
      if (!this.userAddress) {
        return "Connect";
      }

      if (this.stake) {
        if (this.Avax <= 0 || this.Avax == "") return "Input";
        if (this.Avax > this.AvaxBal) return "Insufficient Balance";
        else return "Stake";
      } else {
        if (this.gAvax <= 0 || this.gAvax == "") return "Input";
        if (!this.approval) return "Set Approval";
        if (this.gAvax > this.gAvaxBal) return "Insufficient Balance";
        else return "Unstake";
      }
    },
    isDisabled: function () {
      return (
        this.loading ||
        !(
          this.ButtonText == "Stake" ||
          this.ButtonText == "Unstake" ||
          this.ButtonText == "Set Approval" ||
          this.ButtonText == "Connect"
        )
      );
    },
    dayGap: function () {
      var apy = BN(2).div(10000);
      var daily;
      var lost;
      if (this.stake) {
        daily = BN(this.Avax).times(apy);
        lost = BN(this.expected)
          .times(this.price)
          .minus(BN(this.Avax))
          .div(daily)
          .toFixed(1)
          .toString();
      } else {
        daily = BN(this.gAvax).times(apy);
        lost = BN(this.expected)
          .minus(BN(this.gAvax).times(this.price))
          .div(daily)
          .toFixed(1)
          .toString();
      }
      return isNaN(lost) ? 0 : lost > 1000 ? "a lot " : lost;
    },
  },
  watch: {
    async userAddress() {
      if (this.contracts) {
        await this.update();
      }
    },
    async contracts() {
      if (this.userAddress) {
        await this.update();
      }
    },
  },
  methods: {
    ...mapActions(["setAddress"]),
    async update() {
      this.loading = true;
      await this.clearValues();
      await this.updateData();
      await this.updateExpected();
      this.loading = false;
    },
    async clearValues() {
      this.loading = true;
      this.Avax = BN(0).toString();
      this.gAvax = BN(0).toString();
      this.expected = BN(0).toString();
      this.AvaxBal = BN(0).toString();
      this.gAvaxBal = BN(0).toString();
      this.price = BN(0).toString();
      this.approval = false;
      this.$refs.InputSpace1.Clear();
      this.$refs.InputSpace2.Clear();
      this.loading = false;
    },
    async updateData() {
      this.loading = true;
      await timeout(2000);
      if (this.web3) {
        this.AvaxBal = BN(await this.web3.eth.getBalance(this.userAddress))
          .div(1e18)
          .minus("0.05")
          .toString();
        this.gAvaxBal = BN(
          await this.contracts.gAVAX.methods
            .balanceOf(this.userAddress, this.chosenID)
            .call()
        )
          .div(1e18)
          .toString();
        this.price = BN(
          await this.contracts.gAVAX.methods.pricePerShare(this.chosenID).call()
        ).div(1e18);
        this.wpAddress = await this.contracts.Portal.methods
          .planetWithdrawalPool(this.chosenID)
          .call();
        this.approval = await this.contracts.gAVAX.methods
          .isApprovedForAll(this.userAddress, this.wpAddress)
          .call();
      }
      this.loading = false;
    },
    async Switch(isStake) {
      this.stake =
        isStake === true ? true : isStake === false ? false : !this.stake;
      await this.update();
    },
    async setValue(name, value) {
      if (name == "Avax") {
        this.Avax = value;
      } else {
        this.gAvax = value;
      }
      await this.updateExpected();
    },
    setSlippage(value) {
      this.slippage = value;
    },
    setDeadline(value) {
      this.deadline = value;
    },
    async updateExpected() {
      const wpAddress = await this.contracts.Portal.methods
        .planetWithdrawalPool(this.chosenID)
        .call();
      const wpool = getWithdrawalPool(wpAddress);
      if (this.stake && this.Avax > 0) {
        var calVal;
        const debt = BN(await wpool.methods.getDebt().call());
        if (debt.gt(BN(this.Avax).times(1e18))) {
          calVal = BN(
            await wpool.methods
              .calculateSwap(0, 1, BN(this.Avax).times(1e18).toString())
              .call()
          );
        } else {
          calVal = BN(this.Avax).div(this.price).times(1e18);
        }
      } else if (this.gAvax > 0) {
        calVal = BN(
          await wpool.methods
            .calculateSwap(1, 0, BN(this.gAvax).times(1e18).toString())
            .call()
        );
      } else {
        this.expected = 0;
        return;
      }
      this.expected = calVal.div(1e18).toString();
    },
    async Approve() {
      await this.contracts.gAVAX.methods
        .setApprovalForAll(this.wpAddress, true)
        .send({
          from: this.userAddress,
        })
        .on("transactionHash", function (hash) {
          notifyHandler(hash);
        })
        .once("confirmation", async function () {
          await this.updateData();
        })
        .on("error", (err) => {
          console.log(err);
        })
        .catch((err) => {
          console.log(err);
        });
      await this.updateData();
    },
    async Execute() {
      const self = this;
      this.loading = true;
      if (this.ButtonText == "Connect") {
        await this.setAddress();
        this.loading = false;
        return;
      }
      if (!this.stake & (this.ButtonText == "Set Approval")) {
        await this.Approve();
        this.loading = false;
        return;
      }
      const deadline = Math.floor(Date.now() / 1000 + this.deadline * 60);
      if (this.ButtonText == "Stake") {
        const min = BN(this.expected)
          .times(1e18)
          .times(100 - this.slippage)
          .dividedBy(100)
          .toFixed(0)
          .toString();
        await this.contracts.Portal.methods
          .stake(this.chosenID, min, deadline)
          .send({
            from: this.userAddress,
            value: BN(this.Avax).times(1e18).toString(),
          })
          .on("transactionHash", function (hash) {
            notifyHandler(hash);
          })
          .once("confirmation", async function () {
            await self.update();
          })
          .on("error", (err) => {
            console.log(err);
          })
          .catch((err) => {
            console.log(err);
          });
        await self.update();
        return;
      }
      if (this.ButtonText == "Unstake") {
        const min = BN(this.expected)
          .times(1e18)
          .times(100 - this.slippage)
          .dividedBy(100)
          .toFixed(0)
          .toString();
        const wpool = getWithdrawalPool(this.wpAddress);
        await wpool.methods
          .swap(1, 0, BN(this.gAvax).times(1e18).toString(), min, deadline)
          .send({
            from: this.userAddress,
          })
          .on("transactionHash", function (hash) {
            notifyHandler(hash);
          })
          .once("confirmation", async function () {
            await self.update();
          })
          .on("error", (err) => {
            console.log(err);
          })
          .catch((err) => {
            console.log(err);
          });
        await self.update();
        return;
      }
    },
  },
};
</script>
<style scoped>
.Staker {
  box-sizing: border-box;
  color: #fff;
  min-height: 100%;
  margin: 0 50px 0 0;
  padding: 40px;
  background-image: url("~@/assets/bg.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 16px;
}
.Staker-wrap {
  border-radius: 10px;
  align-self: center;
}
.poolAction {
  margin-top: 25px;
  height: calc(100% - 163px);
  padding: 20px 3% 0 3%;
  border-radius: 6px;
}
.split-two {
  padding: 0 3%;
}
.switch-on-button {
  text-align: center;
  font-size: 18px;
  padding: 16px 0px;
  border-radius: 4px;
  background-color: #18aaa180;
  color: #fff;
  transition: 0.3s ease-in-out;
}
.switch-off-button {
  text-align: center;
  font-size: 16px;
  padding: 16px 0px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  background-color: transparent;
  color: rgb(255, 255, 255, 0.6);
}
.button-wrap {
  padding: 30px 0 0 0;
}
.Execute-button {
  padding: 15px 40px;
  align-self: flex-end;
  transition: 0.3s;
}
.split-two {
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;
  grid-template-areas: "left right";
}

.inputSpaces {
  display: grid;
  grid-template-areas: "a" "s" "g";
  gap: 5px 5px;
}
.inputSpaces-unstake {
  display: grid;
  grid-template-areas: "g" "s" "a";
  gap: 5px 5px;
}
#AvaxSpace {
  grid-area: a;
}
#downButton {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: s;
}
#gAvaxSpace {
  grid-area: g;
}
#price {
  width: 50%;
  grid-area: p;
  align-self: center;
  justify-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: #18aaa1;
  border-radius: 10px;
  padding: 10px 16px;
}
.warndata {
  padding: 5px;
}
.left-section {
  margin-top: 25px;
  min-height: 150px;
}
@media screen and (max-width: 1400px) {
  .Staker {
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 819px) {
  .split-two > .left-section {
    margin-bottom: 30px;
  }
  .inputSpaces {
    grid-template-areas: "a" "s" "g" "p";
  }
  .inputSpaces-unstake {
    grid-template-areas: "g" "s" "a" "p";
  }
}

@media screen and (max-width: 620px) {
  .Staker {
    padding: 32px 24px;
  }
  .button-wrap {
    padding: 0;
  }
  .poolAction {
    padding: 20px 0;
  }
}
</style>