<template>
  <div class="container">
    <div class="small-title">
      {{ header }}
      <Tip :text="tip" :white="true" />
    </div>
    <div class="small-data">
      <ImageVue
        :src="'dynamic/loading.svg'"
        class="loading"
        size="40px"
        v-if="loading"
      />
      <span v-else> {{ data }}</span>
    </div>
    <button @click="$emit('clicked')" class="cornerButton">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
import Tip from "@/components/utils/Tip";
import ImageVue from "@/components/utils/ImageVue";

export default {
  props: ["header", "data", "tip", "buttonText", "loading"],
  components: { Tip, ImageVue },
};
</script>

<style scoped>
.container {
  padding: 20px;
  border-radius: 8px;
  position: relative;
}
</style>