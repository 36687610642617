<template>
  <div class="Exp">
    <div class="m">{{ m }}</div>
    <div class="title">
      <router-link :to="{ path: '/' }" v-if="back" class="img-wrapper">
        <ImageVue
          :src="darkTheme ? 'dynamic/back_white.svg' : 'dynamic/back.svg'"
          size="32px"
        />
      </router-link>
      <div class="gap-32" v-else />
      {{ title }}
    </div>
    <div class="p">{{ p }}</div>
    <br />
  </div>
</template>

<script>
import ImageVue from "./ImageVue";
import { mapGetters } from "vuex";
export default {
  props: ["title", "m", "p", "back"],
  components: { ImageVue },
  computed: {
    ...mapGetters({ darkTheme: "darkTheme" }),
  },
};
</script>

<style scoped>
.Exp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  display: flex;
  font-weight: 700;
  font-size: 64px;
}
.p {
  font-size: 20px;
  font-weight: 500;
  padding: 20px 20px;
  opacity: 0.86;
  color: rgba(0, 0, 0, 0.5);
  max-width: 50ch;
  text-align: center;
}
.m {
  font-size: 16px;
  font-weight: 700;
  color: rgba(11, 70, 36, 0.75);
  opacity: 0.94;
  padding-bottom: 0.5ch;
  /* color: rgba(26, 11, 70, 0.75); */
}
.DarkMode .title {
  color: #fff;
}
.DarkMode .p {
  color: rgba(255, 255, 255, 0.5);
}
.DarkMode .m {
  color: rgba(11, 255, 255, 0.75);
}

@media screen and (max-width: 819px) {
  .title {
    font-size: 40px;
  }
  .m {
    font-size: 14px;
  }
  .p {
    text-align: center;
    font-size: 16px;
  }
}
</style>