<template>
  <div class="inputSpace">
    <div class="inputBody">
      <div class="name">
        <p>{{ name }}</p>
      </div>
      <input
        v-if="!(data === false)"
        class="token-amount-input"
        inputmode="decimal"
        v-model.number="data"
        readonly
      />
      <input
        v-else
        v-model.number="value"
        @keypress="isNumber($event)"
        class="token-amount-input"
        inputmode="decimal"
        title=""
        autocomplete="off"
        autocorrect="off"
        type="number"
        pattern="^[0-9]*[.,]?[0-9]*$"
        placeholder="0.0"
        minlength="1"
        maxlength="39"
        spellcheck="false"
      />
      <button @click="Maxed" v-if="data === false" class="maxbutton">
        MAX
      </button>
      <ImageVue
        v-else
        :src="'tokens/' + name + '.png'"
        :size="'32px'"
        class="logo"
      />
    </div>
  </div>
</template>

<script>
import ImageVue from "./ImageVue";
export default {
  components: { ImageVue },
  props: ["name", "max", "data"],
  data: () => ({ value: "" }),
  watch: {
    value(newValue) {
      this.$emit("update", this.name, newValue);
    },
  },
  methods: {
    Update(v) {
      this.value = v;
    },
    Clear() {
      this.Update("");
      this.$emit("cleared");
    },
    Maxed() {
      this.Update(this.max);
      this.$emit("maxed", this.name);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.inputSpace {
  padding-left: 15px;
  height: 64px;
  border-style: solid;
  border-width: 2px;
  border-color: #18aaa180;
  background-color: transparent;
  border-radius: 6px;
  margin: auto;
  font-size: 18px;
}
.inputBody {
  position: relative;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-template-areas: ". . .";
  align-items: center;
  justify-content: center;
}
.maxbutton {
  font-size: 18px;
  line-height: 40px;
  height: 40px;
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0);
}
.maxbutton:hover {
  color: rgba(255, 255, 255, 1);
}
.token-amount-input {
  box-sizing: border-box;
  z-index: 10;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  font-size: 18px;
  display: inline-block;
  min-width: 70%;
  color: #fff;
  background-color: transparent;
  outline-width: 0;
  line-height: 40px;
  text-align: right;
  height: 40px;
  padding-bottom: 0;
  text-overflow: ellipsis;
  border-radius: 2px;
  border: none;
  touch-action: manipulation;
}
.name {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
.logo {
  align-self: center;
  justify-self: center;
}
@media screen and (max-width: 620px){
  .inputSpace {
    height: 52px;
  }
  .name > p, .token-amount-input, .maxbutton {
    margin: auto 0;
    font-size: 15px;
  }
}
</style>