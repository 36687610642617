<template>
  <div class="Portal">
    <Explainer
      m="NO TRUST NEEDED"
      title="Geode Portal"
      p="Portal creates and maintains DAO owned Liquid Staking Derivatives, what we call 'Planets'."
      :back="true"
    />
    <div class="portal-wrapper">
      <Staker :mobile="mobile" class="staker" />
      <Overview :mobile="mobile" class="overview" />
    </div>
  </div>
</template>

<script>
import Overview from "./overview";
import Staker from "./staker";
import Explainer from "@/components/utils/Explainer";
export default {
  components: { Overview, Staker, Explainer },
  props: ["mobile"],
};
</script>

<style scoped>
.Portal {
  box-sizing: border-box;
  max-width: 1800px;
}
.portal-wrapper {
  display: flex;
  padding-top: 40px;
  flex-direction: row;
  margin-bottom: 100px;
}
@media screen and (max-width: 1400px) {
  .Portal {
    flex-direction: column-reverse;
  }
  .portal-wrapper {
    flex-direction: column;
    gap: 50px;
  }
  .overview,
  .staker {
    padding-top: 5vh;
  }
}
@media screen and (max-width: 1100px) {
  .Portal {
    padding: 0 32px;
  }
}
@media screen and (max-width: 620px) {
  .Portal {
    padding: 0 12px;
  }
}
</style>