import { render, staticRenderFns } from "./InputSpace.vue?vue&type=template&id=0f791712&scoped=true&"
import script from "./InputSpace.vue?vue&type=script&lang=js&"
export * from "./InputSpace.vue?vue&type=script&lang=js&"
import style0 from "./InputSpace.vue?vue&type=style&index=0&id=0f791712&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f791712",
  null
  
)

export default component.exports