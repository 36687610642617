<template>
  <div class="container">
    <input type="checkbox" v-model="checked" onclick="return false" />
    <span class="checkmark"></span>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      checked: true,
    };
  },
  mounted: function () {
    this.checked = this.value;
  },
  watch: {
    checked(newVal) {
      this.$emit("change", newVal);
    },
    value() {
      this.checked = this.value;
    },
  },
};
</script>


<style scoped>
input {
  opacity: 0;
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin: 0px;
}
.container {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark {
  border: solid rgba(24, 170, 161, 0.25) 1px;
  position: absolute;
  z-index: 1;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background-color: #062322;
  pointer-events: none;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  /*background-color: #aaa;*/
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  /*background-color: #aaa;*/
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 4px;
  width: 6px;
  height: 12px;
  border: solid #18aaa1;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>