<template>
  <div :class="{ clickable: link, statusMain: true }" @click="openLink">
    <div class="centerize header">
      <p class="smaller-data">{{ header }}</p>
      <Tip :text="tip" :outside="true" />
    </div>
    <p v-if="boolean" :class="{ turq: value, red: !value }">
      {{ value ? "Yes" : "No" }}
    </p>
    <div v-else>
      <p class="smaller-data" v-if="value">
        <ImageVue
          :src="'dynamic/loading.svg'"
          class="loading"
          size="24px"
          v-if="loading"
        />
        <span v-else> {{ value }}</span>
      </p>
      <p v-else>___</p>
    </div>
  </div>
</template>

<script>
import ImageVue from "@/components/utils/ImageVue";
import Tip from "@/components/utils/Tip";
export default {
  props: ["header", "boolean", "value", "tip", "link", "loading"],
  components: { Tip, ImageVue },
  methods: {
    openLink() {
      if (this.link) window.open(this.link, "_blank");
    },
  },
};
</script>

<style scoped>
.statusMain {
  min-width: 120px;
}
p {
  margin: 3px;
}
.header {
  border: 0px;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, #18aaa1, transparent);
  border-image-slice: 1;
}
.clickable {
  cursor: pointer;
}
div {
  text-align: center;
}
.red {
  color: #fe2b6e;
}
.turq {
  color: #18aaa1;
}
</style>