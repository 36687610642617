<template>
  <div class="tooltip">
    <ImageVue
      :src="'dynamic/info.svg'"
      size="14px"
      :class="{ white: white, infoImg: outside }"
    />
    <span :class="className">{{ text }}</span>
  </div>
</template>

<script>
import ImageVue from "./ImageVue";
export default {
  props: ["text", "white", "outside"],
  components: { ImageVue },
  data() {
    return {
      className: "tooltiptext",
    };
  },
  mounted() {
    this.className +=
      window.screen.width - this.$el.getBoundingClientRect().x < 135
        ? " carry-left"
        : "";
  },
};
</script>

<style scoped>
.carry-left {
  left: calc(100vw - 125px);
}
.tooltip {
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  width: 15px;
  border: solid 1px;
  border-radius: 50%;
  border-color: transparent;
  color: rgb(235, 235, 245, 0.3);
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.DarkMode .infoImg,
.white {
  filter: invert(1);
}
</style>