<template >
  <div class="PoolOverview">
    <div class="PoolTitle">Planet Status</div>
    <div class="OverviewGrid">
      <div class="PoolCard">
        <div class="image-container align-vertically">
          <ImageVue :src="'tokens/yyAvax.svg'" :size="'100%'" />
        </div>
        <div class="amounts-containers">
          <div class="amount-container">
            <div class="align-vertically small-title">APR:</div>
            <div
              :class="{
                'medium PoolData medium-data APR': !mobile,
                'medium-mobile PoolData medium-data APR': mobile,
              }"
            >
              <ImageVue
                :src="'dynamic/loading.svg'"
                class="loading"
                size="40px"
                v-if="loading"
              />
              <span v-else>
                {{ Number.parseFloat(planet.APR).toFixed(2) }} %
              </span>
            </div>
          </div>
          <div class="amount-container">
            <div class="align-vertically small-title">Total Staked:</div>
            <div
              :class="{
                'medium PoolData medium-data': !mobile,
                'medium-mobile PoolData medium-data': mobile,
              }"
            >
              <ImageVue
                :src="'dynamic/loading.svg'"
                class="loading"
                size="40px"
                v-if="loading"
              />
              <span v-else> {{ planet.totalStaked }}</span>
            </div>
          </div>
          <div class="amount-container">
            <div class="align-vertically small-title">DWP Liquidity:</div>
            <div
              :class="{
                'medium PoolData medium-data': !mobile,
                'medium-mobile PoolData medium-data': mobile,
              }"
            >
              <ImageVue
                :src="'dynamic/loading.svg'"
                class="loading"
                size="40px"
                v-if="loading"
              />
              <span v-else> {{ planet.withdrawalLiquidity }}</span>
            </div>
          </div>
        </div>
      </div>
      <RightContainer
        class="right1 shared-bg"
        header="gAVAX ID"
        :data="
          planet.id
            ? planet.id.length > 42
              ? planet.id.substring(0, 42) + '...'
              : 0
            : 0
        "
        :tip="tips.id"
        :buttonText="'View on Explorer'"
        :loading="loading"
        @clicked="openExplorer"
      ></RightContainer>
      <RightContainer
        class="right2 shared-bg"
        header="ERC20 Interface"
        :data="planet.interface"
        :tip="tips.interface"
        :buttonText="'Add to Metamask'"
        :loading="loading"
        @clicked="addMetamask"
      ></RightContainer>
      <RightContainer
        class="right3 shared-bg"
        header="DWP"
        :data="planet.WPool"
        :tip="tips.WPool"
        :buttonText="'Check it Out'"
        :loading="loading"
        @clicked="routeDWP"
      ></RightContainer>
    </div>
    <div class="DataCards">
      <div class="box-wrap">
        Oracle Price
        <Tip :text="tips.oraclePrice" :outside="true" />
        <div class="box shared-bg">
          <ImageVue
            :src="'dynamic/loading.svg'"
            class="loading"
            size="20px"
            v-if="loading"
          />
          <span v-else> {{ planet.oraclePrice }} Avax </span>
        </div>
      </div>
      <div class="box-wrap">
        Health Rate
        <Tip :text="tips.rate" :outside="true" />
        <div class="box shared-bg">
          <ImageVue
            :src="'dynamic/loading.svg'"
            class="loading"
            size="20px"
            v-if="loading"
          />
          <span v-else> {{ planet.healthRate }} % </span>
        </div>
      </div>
      <div class="box-wrap">
        Planet Debt
        <Tip :text="tips.debt" :outside="true" />
        <div class="box shared-bg">
          <ImageVue
            :src="'dynamic/loading.svg'"
            class="loading"
            size="20px"
            v-if="loading"
          />
          <span v-else> {{ planet.debt }} </span>
        </div>
      </div>
    </div>
    <div class="Status-Container">
      <Status
        header="Staking Active"
        :boolean="true"
        :value="planet.isActive"
        :tip="tips.isActive"
        :loading="loading"
      ></Status>
      <Status
        header="Active Operator"
        :value="planet.activeOperator"
        :tip="tips.activeOperator"
        :link="'https://www.edennetwork.io/'"
        :loading="loading"
      ></Status>
      <Status
        header="pBank"
        :value="
          planet.pBank.length > 10 ? planet.pBank.substring(0, 16) + '...' : ''
        "
        :tip="tips.pBank"
        :link="
          planet.pBank.length > 10
            ? `https://explorer-xp.avax.network/address/${planet.pBank.substring(
                -2
              )}`
            : ''
        "
        :loading="loading"
      ></Status>
      <Status
        header="Planet Fee"
        :value="planet.planetFee + '%'"
        :tip="tips.planetFee"
        :loading="loading"
      ></Status>
      <Status
        header="Operator Fee"
        :value="planet.operatorFee + '%'"
        :tip="tips.operatorFee"
        :loading="loading"
      ></Status>
    </div>
  </div>
</template>

<script>
import ImageVue from "@/components/utils/ImageVue";
import Status from "./status";
import RightContainer from "./container";
import Tip from "@/components/utils/Tip";
import { mapGetters, mapActions } from "vuex";

import axios from "axios";
import BN from "bignumber.js";
import { getPrice, formatter } from "@/utils/common";

export default {
  components: { RightContainer, Status, ImageVue, Tip },
  props: ["mobile"],
  data() {
    return {
      loading: true,
      planet: {
        name: 0,
        totalStaked: 0,
        withdrawalLiquidity: 0,
        id: "---",
        interface: "---",
        WPool: "---",
        debt: 0,
        healthRate: 0,
        isActive: true,
        activeOperator: "----",
        operatorFee: 0,
        planetFee: 0,
        oraclePrice: 0,
        APR: 0,
        pBank: "---",
      },
      tips: {
        id: "Every Planet has a unique ID that allows us to trakc their balance with an erc1155 contract named gAVAX.",
        interface:
          "While the data is kept in ERC1155, your Planet Maintainer can let the derivative act like ERC20!",
        WPool:
          "Dynamic Withdrawal Pools ensure the best price without a waiting period.",
        debt: "Debt is the sum of all unpaid rewards and fees, waiting on the p subchain.",
        pBank:
          "Node Operator's address on the P-Chain, which is tracked by Telescope.",
        rate: "Debt / Liquidity. Withdrawals are cheaper when the pool is healthier.",
        isActive: "This Planet currently allows fresh minting!",
        activeOperator:
          "This Node Operator is allowed to move funds around and create new Validators for the stakers.",
        planetFee:
          "Some portion of the staking rewards is going directly to the treasury of this Planet.",
        operatorFee:
          "A previously agreed portion of the staking rewards is paid to the  Node Operator.",
        oraclePrice:
          "Oracle works every 24h to distribute staking rewards by increasing this price. In terms of AVAX",
      },
    };
  },
  async mounted() {
    this.loading = true;
    this.id = this.$route.params.id;
    const { price } = await getPrice("avax");
    var planet = (
      await axios.get(`https://api-avax-1efd0eddc708.herokuapp.com/api/avax/portal/planet/${this.id}`)
    ).data.data;

    var inPlanet = {
      name: planet.name,
      totalStaked: formatter.format(
        BN(planet.totalStaked).times(price).div(1e18).toFixed(0).toString()
      ),
      withdrawalLiquidity: formatter.format(
        BN(planet.withdrawalLiquidity)
          .times(price)
          .div(1e18)
          .toFixed(0)
          .toString()
      ),
      id: this.id,
      interface: planet.interface,
      WPool: planet.WPool,
      debt: formatter.format(
        BN(planet.debt).times(price).div(1e18).toFixed(3).toString()
      ),
      healthRate: BN(planet.healthRate).times(100).toFixed(3).toString(),
      isActive: planet.isActive,
      activeOperator: planet.activeOperator.name,
      pBank: planet.activeOperator.pBank,
      APR: BN(planet.APR).toString(),
      operatorFee: planet.activeOperator.fee,
      planetFee: planet.maintainerFee,
      oraclePrice: BN(planet.oraclePrice).div(1e18).toFixed(4).toString(),
    };
    this.planet = inPlanet;
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      userAddress: "userAddress",
    }),
  },
  methods: {
    ...mapActions(["setAddress"]),
    openExplorer() {
      window.open(
        `https://snowtrace.io/token/0x6026a85e11bd895c934af02647e8c7b4ea2d9808?a=${this.planet.id}`,
        "_blank"
      );
    },
    async addMetamask() {
      if (!this.userAddress) {
        await this.setAddress();
        return;
      }
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: this.planet.interface, // The address that the token is at.
            symbol: "yyAVAX", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image:
              "https://storage.googleapis.com/yak/tokens/0xF7D9281e8e363584973F946201b82ba72C965D27.png", // A string url of the token logo
          },
        },
      });
    },
    routeDWP() {
      this.$router.push({
        name: "DWP",
        params: { id: this.planet.id },
      });
    },
  },
};
</script>
<style scoped >
.PoolOverview {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 0 50px;
}
.PoolTitle {
  font-weight: 700;
  font-size: 52px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}
.OverviewGrid {
  display: grid;
  margin-top: 50px;
  gap: 30px 50px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "PoolCard right1"
    "PoolCard right2"
    "PoolCard right3";
}
.PoolCard {
  grid-area: PoolCard;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-image: url("~@/assets/bg.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
}
.DataCards {
  display: grid;
  gap: 40px 40px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "box box box";
  margin-top: 40px;
}
.image-container {
  max-width: 75px;
  max-height: 75px;
  width: 30vw;
  height: 30vw;
  border: double 3px transparent;
  background-image: linear-gradient(rgba(3, 28, 26, 1), rgba(3, 28, 26, 1)),
    linear-gradient(to right, rgb(255, 61, 122, 0.75), rgb(1, 234, 214, 0.75));
  border-radius: 50%;
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center;
}
.Status-Container {
  margin: 50px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 25px 0px;
  justify-content: space-around;
}
.PoolData {
  padding: 0 0 15px 0;
}
.split-three {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "fr1 fr2 fr3";
}
.box {
  border-radius: 4px;
  text-align: center;
  padding: 18px 22px;
}
.circle {
  height: 25px;
  margin-right: 14px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
.amounts-containers {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.amount-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
}
.shared-bg {
  color: #fff;
  background-image: url("~@/assets/bg.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* <- here it is */
}
.APR {
  font-size: 32px;
}
@media screen and (max-width: 1800px) {
}
@media screen and (max-width: 1100px) {
  .PoolOverview {
    padding: 32px 0;
  }
  .OverviewGrid {
    display: grid;
    gap: 30px 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1.3fr 1fr 1fr 1fr;
    grid-template-areas:
      "PoolCard"
      "right1"
      "right2"
      "right3";
  }
  .image-container {
    height: 100%;
    max-height: 100px;
    max-width: 100px;
    margin-left: 25px;
  }
  .PoolCard {
    flex-direction: row;
    justify-content: space-between;
  }
  .amounts-containers {
    width: 75%;
    margin-left: 15px;
    flex-direction: row;
    justify-content: space-around;
  }
  .split-three {
    gap: 5px;
  }
}
@media screen and (max-width: 819px) {
  .OverviewGrid {
    display: flex;
    flex-direction: column;
  }
  .PoolCard {
    padding: 28px 24px;
  }
  .amounts-containers {
    padding: 0;
    margin-left: 0;
  }
  .image-container {
    max-height: 64px;
    max-width: 64px;
    margin-left: 0;
  }
  .APR {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .split-three {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "fr1 fr1"
      "fr2 fr3";
  }
}
@media screen and (max-width: 620px) {
  .split-three {
    display: block;
  }
  .box-wrap {
    font-size: 14px;
  }
  .DataCards {
    gap: 40px 10px;
  }
}

@media screen and (max-width: 420px) {
  .amounts-containers {
    flex-direction: column;
    margin-left: 10%;
  }
  .amount-container {
    padding: 0;
  }
  .image-container {
    width: 400px;
    max-height: 160px;
    max-width: 160px;
  }
}
.padding {
  padding: 15px 0px;
}
.left1 {
  grid-area: left1;
}
.left2 {
  grid-area: left2;
}
.right1 {
  grid-area: right1;
}
.right2 {
  grid-area: right2;
}
.right3 {
  grid-area: right3;
}

.split-three > .fr1 {
  grid-area: fr1;
}
.split-three > .fr2 {
  grid-area: fr2;
}
.split-three > .fr3 {
  grid-area: fr3;
}
</style>
